'use strict'

const getFileNameByEnv = require('@wix/dbsm-common/src/getFileNameByEnv')

const htmlFor = baseName => getFileNameByEnv(`${baseName}.html`)
const vmFor = baseName => getFileNameByEnv(`${baseName}.vm`)

const panelDef = (chunkName, entryPoint) => ({
  html: htmlFor(chunkName),
  vm: vmFor(chunkName),
  entryPoint,
  url: isNewWorkspace =>
    `./${htmlFor(chunkName)}${isNewWorkspace ? '?isNewWorkspace=true' : ''}`
})

module.exports = {
  newPagePanel: panelDef(
    'newPagePanel',
    'custom-router/panels/new-page/newPagePanel.js'
  ),
  updatePrefixPanel: panelDef(
    'updatePrefixPanel',
    'custom-router/panels/update-prefix/updatePrefixPanel.js'
  ),
  connectPagePanel: panelDef(
    'connectPagePanel',
    'custom-router/panels/connect-page/connectPagePanel.js'
  ),
  deletePagePanel: panelDef(
    'deletePagePanel',
    'custom-router/panels/delete-page/deletePagePanel.js'
  ),
  pageInfoPanel: panelDef(
    'pageInfoPanel',
    'custom-router/panels/page-info/pageInfoPanel.js'
  ),
  pageSeoPanel: panelDef(
    'pageSeoPanel',
    'custom-router/panels/page-seo/pageSeoPanel.js'
  ),
  webmodulesPermissionsPanel: panelDef(
    'webmodulesPermissionsPanel',
    'webmodules-permissions/webmodulesPermissionsPanel.js'
  ),
  npmModulesManagementPanel: panelDef(
    'npmModulesManagementPanel',
    'npm-modules/panels/packages-manager/npmModulesManagementPanel.js'
  ),
  removeNpmPackagePanel: panelDef(
    'removeNpmPackagePanel',
    'npm-modules/panels/uninstall-package/removeNpmPackagePanel.js'
  ),
  openDeleteRoutersJsFilePanel: panelDef(
    'deleteRoutersJsFilePanel',
    'custom-router/panels/delete-routers-js/deleteRoutersJsFilePanel.js'
  )
}
