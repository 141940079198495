const {
  removeNpmPackagePanel: removeNpmPackagePanelDef
} = require('../../../panels/panelDefs')

module.exports = (
  { editorSDKProxy },
  { packageNameToUninstall, packageVersion },
  isNewWorkspace
) =>
  editorSDKProxy.editor.openModalPanel({
    url: removeNpmPackagePanelDef.url(isNewWorkspace),
    width: 564,
    height: 306,
    initialData: { packageNameToUninstall, packageVersion },
    shouldHideHeader: true
  })
