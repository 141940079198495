'use strict'

const {
  registerToUnexpectedErrors
} = require('@wix/dbsm-common/src/errors/registerToWorkerUnexpectedErrors')
const { create: createLogger } = require('@wix/wix-code-client-logger')
const { APP_NAME } = require('../../../src/consts')

const appLogger = ({ global, handlerCreators }) => {
  const logger = createLogger({
    handlerCreators
  })

  const cleanup = registerToUnexpectedErrors({
    onError: error => logger.error(error),
    appName: APP_NAME,
    global
  })

  return { logger, cleanup }
}

module.exports.loggerCreator = appLogger
