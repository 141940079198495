'use strict'

const create = () => {
  const consoleMessages = []

  const addMessage = (editorSDKProxy, message) => {
    consoleMessages.push(message)
    editorSDKProxy.editor.updateAppState({
      consoleMessages
    })
  }

  const clearMessages = () => {
    consoleMessages.length = 0
  }

  return {
    addMessage,
    clearMessages
  }
}

module.exports.create = create
