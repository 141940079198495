const {
  npmModulesManagementPanel: npmModulesManagementPanelDef
} = require('../../../panels/panelDefs')
const panelDimensions = require('./panelDimensions')

module.exports = (
  { editorSDKProxy },
  { modulesMetadata, tabId },
  isNewWorkspace
) =>
  editorSDKProxy.editor.openModalPanel(
    Object.assign(
      {},
      {
        url: npmModulesManagementPanelDef.url(isNewWorkspace),
        initialData: {
          tabId,
          modulesMetadata
        },
        shouldHideHeader: true
      },
      panelDimensions.packageManager
    )
  )
