'use strict'
const panelDefs = require('../../../panels/panelDefs')

module.exports = (routerRef, isNewWorkspace) => ({
  url: panelDefs.updatePrefixPanel.url(isNewWorkspace),
  width: '744',
  height: isNewWorkspace ? '522' : '564',
  shouldHideHeader: true,
  initialData: {
    routerRef
  }
})
