'use strict'

module.exports = {
  PUBLIC_ID: 'public/',
  PAGES_ROOT: 'pages/',
  JS_EXTENSION: '.js',
  PAGE_NAME_SUFFIX: '-page',
  MAX_PREFIX_LEN: 100,
  BACK_END_ID: 'backend/',
  ROUTER_FILE_NAME: 'routers.js',
  ROUTER_FUNCTION_SUFFIX: '_Router',
  SITE_MAP_FUNCTION_SUFFIX: '_SiteMap',
  URL_DOMAIN: 'https://www.../',
  errors: {
    PAGE_TITLE_NOT_VALID_SPECIAL_CHARECTERS:
      'page title not valid- can not contain special charecter',
    PAGE_TITLE_NOT_VALID_BLANK: 'Validations_Page_Name_Too_Short',
    PAGE_TITLE_NOT_VALID_LENGTH: 'Validations_Page_Name_Too_Long',
    PAGE_TITLE_NOT_VALID_HTML_TAGS: 'Validations_Page_Name_Not_Valid_Tags',
    ROUTER_PREFIX_EXIST: 'WixCode_Create_Router_Prefix_InUse'
  },
  UNDO_REDO_LABELS: {
    CONNECT_PAGE_TO_CUSTOM_ROUTER: 'connectPageToCustomRouter',
    ADD_CUSTOM_ROUTER: 'addCustomRouter',
    REMOVE_CUSTOM_ROUTER: 'removeCustomRouter',
    REMOVE_PAGE_FROM_CUSTOM_ROUTER: 'removePageFromCustomRouter',
    DISCONNECT_CUSTOM_ROUTER: 'disconnectCustomRouter',
    UPDATE_ROUTER_PREFIX: 'updateCustomRouterPrefix'
  },
  USER_PREFERENCES: {
    CREATE_CUSTOM_ROUTER_INTRO: {
      DONT_SHOW_AGAIN: 'createCustomRouter.firstTime.dontShowAgain'
    }
  }
}
