'use strict'

const { matchAny } = require('@wix/wix-code-client-logger')
const sendBiOnError = require('@wix/dbsm-common/src/bi/sendBiOnError')

const {
  BI_ERROR_ENDPOINT,
  BI_SOURCE
} = require('@wix/dbsm-common/src/bi/constants')
const { loggerFactory } = require('@wix/dbsm-common/src/bi/bi.js')
const { getAppUrl } = require('@wix/dbsm-common/src/worker/getUrl')
const getAppVersion = require('@wix/dbsm-common/src/getAppVersion')
const webBiLogger = require('@wix/web-bi-logger').default

const { APP_NAME } = require('../../../consts')

const createLogger = () => {
  const factory = webBiLogger
    .factory({ endpoint: BI_ERROR_ENDPOINT })
    .updateDefaults({
      src: BI_SOURCE,
      ver: getAppVersion(getAppUrl(APP_NAME)),
      app_name: APP_NAME,
      evid: 10
    })

  return loggerFactory(factory)
}

const biHandlerCreator = () => {
  const errorBiLogger = createLogger()

  const biHandler = () => ({
    init: () => {},
    log: logEvent => {
      logEvent.matchWith({
        Error: ({ error }) => {
          sendBiOnError(errorBiLogger, error)
        },
        [matchAny]: () => {}
      })
    }
  })

  return biHandler
}

module.exports.biHandlerCreator = biHandlerCreator
