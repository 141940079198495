'use strict'

const {
  registerToUnexpectedErrors
} = require('@wix/dbsm-common/src/errors/registerToPanelUnexpectedErrors')
const { create: createLogger } = require('@wix/wix-code-client-logger')

const appLogger = ({ global, handlerCreators }) => {
  const logger = createLogger({
    handlerCreators
  })

  const onError = error => logger.error(error)

  const cleanup = registerToUnexpectedErrors({ global, onError })

  return { logger, cleanup }
}

module.exports.loggerCreator = appLogger
