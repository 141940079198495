'use strict'

const { union } = require('@wix/wix-code-adt')
const { matchAny } = require('@wix/wix-code-client-logger')
const noop_ = require('lodash/noop')
const merge_ = require('lodash/merge')
const {
  configureForEditorWorker
} = require('@wix/dbsm-common/src/raven/configureForEditorWorker')
const { APP_NAME, SENTRY_DSN } = require('../../../consts')

const ravenHandlerCreator = ({ global, createClient, ravenConfig }) => {
  const Raven = createClient()

  const cleanup = configureForEditorWorker({
    Raven,
    appName: APP_NAME,
    global,
    dsn: SENTRY_DSN,
    ravenConfig
  })

  const createReportOptions = ({ level, sessionData, options = {} }) => {
    return merge_({ level }, { extra: sessionData }, options)
  }

  const ravenHandler = () => ({
    init: ({ user }) => {
      Raven.setUserContext(user)
    },
    log: logEvent => {
      logEvent.matchWith({
        Trace: ({ payload }) =>
          payload.matchWith({
            Breadcrumb: breadcrumb => Raven.captureBreadcrumb(breadcrumb),
            [union.any]: noop_
          }),
        Info: ({ message, options, sessionData }) => {
          Raven.captureMessage(
            message,
            createReportOptions({ level: 'info', sessionData, options })
          )
        },
        Warn: ({ message, options, sessionData }) => {
          Raven.captureMessage(
            message,
            createReportOptions({ level: 'warning', sessionData, options })
          )
        },
        Error: ({ error, options, sessionData }) =>
          Raven.captureException(
            error,
            createReportOptions({ sessionData, options })
          ),
        [matchAny]: noop_
      })
    }
  })

  return { ravenHandler, cleanup }
}

module.exports.ravenHandlerCreator = ravenHandlerCreator
