'use strict'

const noop_ = require('lodash/noop')
const { union } = require('@wix/wix-code-adt')
const { matchAny } = require('@wix/wix-code-client-logger')
const { createHooks } = require('./createHooks')
const createFedopsLogger = require('@wix/fedops-logger').create

const Environment = union('Environment', {
  NotInitialized() {},
  Initialized({ appLogger, fedopsId }) {
    const { startHook, endHook, timeoutHook } = createHooks(appLogger)
    const logger = createFedopsLogger(fedopsId, {
      interactionTimeout: 3000,
      startHook,
      timeoutHook,
      endHook
    })

    return { logger }
  }
})

const fedopsHandlerCreator = () => {
  let environment = Environment.NotInitialized()
  const tracesMap = new Map()

  const getLoggerOrThrow = () =>
    environment.matchWith({
      Initialized: ({ logger }) => logger,
      NotInitialized: () => {
        throw new Error(
          `You cannot report to fedops before setting the logger environment.
            Make sure you call logger.init before reporting.`
        )
      }
    })

  const fedopsHandler = () => ({
    init: ({ appLogger, fedopsId }) => {
      environment = Environment.Initialized({ appLogger, fedopsId })
    },
    log: logEvent => {
      logEvent.matchWith({
        Trace: ({ payload, position }) => {
          payload.matchWith({
            AppLoadFinished: () => {
              const logger = getLoggerOrThrow()
              logger.appLoaded()
            },
            Action: ({ actionName, options }) => {
              position.matchWith({
                Start: ({ traceId }) => {
                  const logger = getLoggerOrThrow()
                  const { timeoutId } = logger.interactionStarted(
                    actionName,
                    options
                  )
                  tracesMap.set(traceId, { timeoutId })
                },
                End: ({ traceId, result }) => {
                  result.map(() => {
                    const logger = getLoggerOrThrow()
                    const { timeoutId } = tracesMap.get(traceId)
                    tracesMap.delete(traceId)
                    logger.interactionEnded(actionName, { timeoutId })
                  })
                },
                [matchAny]: noop_
              })
            },
            [union.any]: noop_
          })
        },
        [matchAny]: noop_
      })
    }
  })

  return fedopsHandler
}

module.exports.fedopsHandlerCreator = fedopsHandlerCreator
