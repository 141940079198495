'use strict'

const { isLocalhost } = require('@wix/dbsm-common/src/worker/getUrl')
const { loggerCreator } = require('./logger')
const { consoleHandlerCreator } = require('@wix/wix-code-client-logger')
const { ravenHandlerCreator } = require('./handlers/raven')
const { biHandlerCreator } = require('./handlers/bi')
const RavenConstructor = require('raven-js/src/raven')

const loggerWithHandlers = ({ global }) => {
  const { consoleHandler } = consoleHandlerCreator({ shouldLog: isLocalhost })

  const { ravenHandler } = ravenHandlerCreator({
    global,
    createClient: () => new RavenConstructor()
  })

  const biHandler = biHandlerCreator()

  const { logger } = loggerCreator({
    global,
    handlerCreators: [consoleHandler, ravenHandler, biHandler]
  })

  return logger
}

module.exports.logger = loggerWithHandlers
