'use strict'

const noop_ = require('lodash/noop')
const omit_ = require('lodash/omit')
const { union } = require('@wix/wix-code-adt')
const { matchAny } = require('@wix/wix-code-client-logger')
const sendBiOnError = require('@wix/dbsm-common/src/bi/sendBiOnError')
const {
  BI_ENDPOINT,
  BI_ERROR_ENDPOINT
} = require('@wix/dbsm-common/src/bi/constants')
const {
  undefinedFilterMutator,
  timestampDeltaMutator,
  loggerFactory
} = require('@wix/dbsm-common/src/bi/bi')

const webBiLogger = require('@wix/web-bi-logger').default

const Environment = union('Environment', {
  NotInitialized() {},
  Initialized({ editorTimestamp, defaults, appLogger }) {
    const errorLoggerDefaults = Object.assign({}, defaults, {
      evid: 10
    })

    const biFactory = webBiLogger
      .factory({ endpoint: BI_ENDPOINT })
      .updateDefaults(defaults)

    const errorBiFactory = webBiLogger
      .factory({ endpoint: BI_ERROR_ENDPOINT })
      .updateDefaults(omit_(errorLoggerDefaults, 'app_id')) // error bi catalog requires app_id to be a guid, but the editor does not yet send a real guid for it

    const biMutators = undefinedFilterMutator(
      timestampDeltaMutator(editorTimestamp)
    )
    const biLogger = loggerFactory(biFactory, biMutators)
    const errorBiLogger = loggerFactory(errorBiFactory, biMutators)

    return { biLogger, errorBiLogger, appLogger }
  }
})

const biHandlerCreator = () => {
  let environment = Environment.NotInitialized()

  const biHandler = () => ({
    init: ({ appLogger, bi: { editorTimestamp, defaults } }) => {
      environment = Environment.Initialized({
        editorTimestamp,
        defaults,
        appLogger
      })
    },
    log: logEvent => {
      logEvent.matchWith({
        BI: ({ biEvent }) => {
          environment.matchWith({
            Initialized: ({ biLogger, appLogger }) => {
              try {
                biLogger(biEvent)
              } catch (err) {
                appLogger.error(err)
              }
            },
            NotInitialized: () => {
              throw new Error(
                `You cannot report to BI before setting the logger environment.
                  Make sure you call logger.init before reporting.`
              )
            }
          })
        },
        Error: ({ error }) => {
          environment.matchWith({
            Initialized: ({ errorBiLogger }) => {
              sendBiOnError(errorBiLogger, error)
            },
            [union.any]: noop_
          })
        },
        [matchAny]: () => {}
      })
    }
  })

  return biHandler
}

module.exports.biHandlerCreator = biHandlerCreator
