'use strict'

module.exports = {
  PANEL_TYPES: {
    REMOVE: 'deleteDynamicPage',
    DISCONNECT: 'disconnectDynamicPage'
  },
  PANEL_TEXTS: {
    deleteDynamicPage: {
      DESCRIPTION: 'Warning_Delete_Page_Paragraph',
      CANCEL_BUTTON: 'Warning_Delete_Page_Button1',
      CONFIRM_BUTTON: 'Warning_Delete_Page_Button2',
      TITLE: 'WARNING_DELETE_PAGE_TITLE'
    },
    disconnectDynamicPage: {
      DESCRIPTION: 'Warning_Remove_Router_Paragraph',
      CANCEL_BUTTON: 'Warning_Remove_Router_Button1',
      CONFIRM_BUTTON: 'Warning_Remove_Router_Button2',
      TITLE: 'WARNING_REMOVE_ROUTER_TITLE'
    }
  },
  NEW_WORKSPACE_PANEL_TEXTS: {
    deleteDynamicPage: {
      DESCRIPTION: 'WixCode_Delete_Router_Text',
      CANCEL_BUTTON: 'WixCode_Delete_Router_CancelButton',
      CONFIRM_BUTTON: 'WixCode_Delete_Router_DeleteButton',
      TITLE: 'WixCode_Delete_Router_Title'
    },
    disconnectDynamicPage: {
      DESCRIPTION: 'WixCode_Remove_Page_Router_Text',
      CANCEL_BUTTON: 'WixCode_Remove_Page_Router_CancelButton',
      CONFIRM_BUTTON: 'WixCode_Remove_Page_Router_DeleteButton',
      TITLE: 'WixCode_Remove_Page_Router_Title'
    }
  }
}
