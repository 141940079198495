const {
  openDeleteRoutersJsFilePanel: openDeleteRoutersJsFilePanelDef
} = require('../../../panels/panelDefs')

module.exports = ({ editorSDKProxy }, initialData, isNewWorkspace) => {
  const dimensions = isNewWorkspace
    ? {
        width: '600',
        height: '246'
      }
    : {
        width: '564',
        height: '330'
      }

  return editorSDKProxy.editor.openModalPanel(
    Object.assign(
      {},
      {
        url: openDeleteRoutersJsFilePanelDef.url(isNewWorkspace),
        shouldHideHeader: true,
        width: dimensions.width,
        height: dimensions.height
      }
    )
  )
}
