'use strict'

const Raven = require('raven-js')
const { loggerCreator } = require('./logger')
const { consoleHandlerCreator } = require('@wix/wix-code-client-logger')
const { ravenHandlerCreator } = require('./handlers/raven')
const { biHandlerCreator } = require('./handlers/bi')
const { fedopsHandlerCreator } = require('./handlers/fedops')

const isLocalhost = appUrl => appUrl.indexOf('://localhost') !== 0

const loggerWithHandlers = ({ global, appUrl }) => {
  const { consoleHandler } = consoleHandlerCreator({
    shouldLog: () => isLocalhost(appUrl)
  })

  const { ravenHandler } = ravenHandlerCreator({
    global,
    appUrl,
    createClient: () => Raven
  })

  const biHandler = biHandlerCreator()

  const fedopsHandler = fedopsHandlerCreator()

  const { logger } = loggerCreator({
    global,
    handlerCreators: [consoleHandler, ravenHandler, biHandler, fedopsHandler]
  })

  return logger
}

module.exports.logger = loggerWithHandlers
