'use strict'
/* global self */

const {
  APP_NAME,
  LOCALE_FALLBACK_URL,
  LOCALE_FOLDER,
  EXPERIMENTS_HOST,
  EXPERIMENTS_SCOPE
} = require('../consts')

const { workerLogger: loggerCreator } = require('../logger')
const appLogger = loggerCreator({
  global: self,
  appName: APP_NAME
})

const i18nCreator = require('@wix/wix-code-i18n/src/fetchCreator')
const createI18nInstance = require('@wix/dbsm-common/src/worker/createI18nInstance')
const englishTranslations = require('../locale/custom-router/messages_en.json')

const onFallback = e => {
  appLogger.info(e)
}

const i18nInstance = createI18nInstance(
  i18nCreator,
  'wix-code-editor-app',
  LOCALE_FOLDER,
  LOCALE_FALLBACK_URL,
  englishTranslations,
  onFallback
)
const { default: Experiments } = require('@wix/wix-experiments')
const experimentsManager = new Experiments({
  scope: EXPERIMENTS_SCOPE,
  baseUrl: EXPERIMENTS_HOST
})

module.exports = require('./workerApi')({
  i18nInstance,
  appLogger,
  experimentsManager
})
