const ExtendableError = require('es6-error')

const ERROR_NAME = 'HttpError'

class HttpError extends ExtendableError {
  constructor(originalError, request) {
    super(originalError.message)
    this.name = ERROR_NAME
    this.originalError = originalError
    this.request = request
  }
}

module.exports.HttpError = HttpError
