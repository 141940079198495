module.exports.packageManager = {
  width: 588,
  height: 576
}
module.exports.packageRequest = {
  width: 504,
  height: 480
}
module.exports.errorPanel = {
  width: 564,
  height: 298
}
