'use strict'

const { union } = require('@wix/wix-code-adt')
const { matchAny } = require('@wix/wix-code-client-logger')
const noop_ = require('lodash/noop')
const pick_ = require('lodash/pick')
const merge_ = require('lodash/merge')
const { SENTRY_DSN } = require('../../../consts')
const {
  configureForPanel
} = require('@wix/dbsm-common/src/raven/configureForPanel')
const reportLoadAfterTimeout = require('@wix/dbsm-common/src/raven/reportLoadAfterTimeout')

const Environment = union('Environment', {
  NotInitialized() {},
  Initialized({ window, panelName }) {
    return { window, panelName }
  }
})

const reportLoadAfterTimeoutIfNeeded = ({ environment, Raven }) => {
  environment.matchWith({
    Initialized: ({ window, panelName }) =>
      reportLoadAfterTimeout({ window, Raven, panelName }),
    NotInitialized: () => {
      throw new Error(
        'Raven environment must be initialized to call `reportLoadAfterTimeout`'
      )
    }
  })
}

const ravenHandlerCreator = ({ global, appUrl, createClient, ravenConfig }) => {
  const Raven = createClient()
  let environment = Environment.NotInitialized()

  const cleanup = configureForPanel({
    Raven,
    dsn: SENTRY_DSN,
    appUrl,
    scope: global,
    ravenConfig
  })

  const createReportOptions = ({ level, sessionData, options = {} }) => {
    return merge_({ level }, { extra: sessionData }, pick_(options, 'extra'))
  }

  const ravenHandler = () => ({
    init: ({ user, window, panelName }) => {
      Raven.setUserContext(user)
      environment = Environment.Initialized({ window, panelName })
    },
    log: logEvent => {
      logEvent.matchWith({
        Trace: ({ payload }) =>
          payload.matchWith({
            Breadcrumb: breadcrumb => Raven.captureBreadcrumb(breadcrumb),
            AppLoadFinished: () =>
              reportLoadAfterTimeoutIfNeeded({ environment, Raven }),
            [union.any]: noop_
          }),
        Info: ({ message, options, sessionData }) => {
          Raven.captureMessage(
            message,
            createReportOptions({ level: 'info', sessionData, options })
          )
        },
        Warn: ({ message, options, sessionData }) => {
          Raven.captureMessage(
            message,
            createReportOptions({ level: 'warning', sessionData, options })
          )
        },
        Error: ({ error, options, sessionData }) =>
          Raven.captureException(
            error,
            createReportOptions({ sessionData, options })
          ),
        [matchAny]: noop_
      })
    }
  })

  return { ravenHandler, cleanup }
}

module.exports.ravenHandlerCreator = ravenHandlerCreator
